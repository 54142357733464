import TingPage from './TingPage';

function App() {
  return (
    <div className="App">

      <TingPage/>
     
    </div>
  );
}

export default App;

import React from "react";
import tinglogo from "./image/ting.png";
import { request, gql } from "graphql-request";
import { useState, useEffect } from "react";
import "./style.css";

const TingPage = () => {
  const [data, setData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      let count = 0;
      const countQuery = gql`
        {
          projectsConnection {
            aggregate {
              count
            }
          }
        }
      `;
      await request(`${process.env.REACT_APP_TING404_API}`, countQuery).then(
        (data) => {
          count = data.projectsConnection.aggregate.count;
        }
      );
      const query = gql`
      {
        projects(first: ${count}) {
          image {
            url
            width
            height
          }
          title
          link
          position
        }
      }
    `;

      await request(`${process.env.REACT_APP_TING404_API}`, query).then(
        (data) => setData(data)
      );
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="main-wrapper">
        <div className="ting-logo">
          <img src={tinglogo} alt={tinglogo} />
        </div>

        <div className="logo-section">
          {data &&
            data.projects
              .sort((a, b) => b.position - a.position)
              .map((e, id) => {
                return (
                  <div className="logo-inside" key={id}>
                    <a href={e.link} target="_blank" rel="noreferrer">
                      <div className="logo-img">
                        <img src={e.image.url} alt="" />
                      </div>
                      <p className="title-font">{e.title}</p>
                    </a>
                  </div>
                );
              })}
        </div>
      </div>
    </>
  );
};

export default TingPage;
